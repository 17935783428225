import React from 'react';
import AuthPlaceholder from './AuthPlaceholder';

const MenuPlaceholder = () => {
  return (
    <div className="placeholder-menu menu">
      <AuthPlaceholder />
      <div className="placeholder-menu__bookmarks">
        <div className="placeholder-row animation" />
      </div>
      <div className="placeholder-menu__list">
        <div className="placeholder-menu__list-i">
          <div className="placeholder-row placeholder-menu__list-i__buy animation" />
        </div>
        <div className="placeholder-menu__list-i">
          <div className="placeholder-row placeholder-menu__list-i__rent animation" />
        </div>
        <div className="placeholder-menu__list-i">
          <div className="placeholder-row placeholder-menu__list-i__agencies animation" />
        </div>
        <div className="placeholder-menu__list-i">
          <div className="placeholder-row placeholder-menu__list-i__residencies animation" />
        </div>
        <div className="placeholder-menu__list-i">
          <div className="placeholder-row placeholder-menu__list-i__bc animation" />
        </div>
      </div>
      <div className="placeholder-menu__footer">
        <div className="placeholder-menu__footer-top">
          <div className="placeholder-menu__footer-top_i">
            <div className="placeholder-row animation" />
          </div>
          <div className="placeholder-menu__footer-top_i">
            <div className="placeholder-row animation" />
          </div>
        </div>
        <div className="placeholder-menu__footer-bottom">
          <div className="placeholder-menu__footer-bottom_i">
            <div className="placeholder-row animation placeholder-menu__footer-bottom_i__title" />
          </div>
          <div className="placeholder-menu__footer-bottom_i">
            <div className="placeholder-row placeholder-menu__footer-bottom_i__tel animation" />
          </div>
          <div className="placeholder-menu__footer-bottom_i">
            <div className="placeholder-row placeholder-menu__footer-bottom_i__tel animation" />
          </div>
          <div className="placeholder-menu__footer-bottom_i">
            <div className="placeholder-row placeholder-menu__footer-bottom_i__email animation" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuPlaceholder;
