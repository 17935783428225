import React from 'react';

const AuthPlaceholder = () => {
  return (
    <div className="placeholder-auth">
      <div className="placeholder-row animation" />
    </div>
  );
};

export default AuthPlaceholder;
