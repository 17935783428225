import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './styles.scss';

const Drawer = ({ toggleDrawer, isVisible, additionalClass, children }) => {
  const drawerClassName = cn(
    'drawer',
    {
      'drawer--opened': isVisible
    },
    additionalClass
  );

  return (
    <div className={drawerClassName}>
      <div
        className="drawer__overlay"
        onClick={toggleDrawer}
        onKeyPress={toggleDrawer}
        role="link"
        tabIndex="0"
        data-cy="close-drawer"
      />
      <div className="drawer__content">{children}</div>
    </div>
  );
};

Drawer.propTypes = {
  toggleDrawer: PropTypes.func,
  isVisible: PropTypes.bool,
  additionalClass: PropTypes.string,
  children: PropTypes.node
};

export default Drawer;
