import React, { useEffect } from 'react';

const BlockerDetector = () => {
  const detectorElement = React.createRef();
  const bannerContainerIds = [
    'js-lotriver-top-banner',
    'js-lotriver-bottom-banner'
  ];

  useEffect(() => {
    if (detectorElement.current.clientHeight === 0) {
      bannerContainerIds.forEach(cId =>
        window.adriverExtentionLoad(0, null, cId)
      );
    }
  }, []);

  return (
    <div
      id="banner_ad"
      ref={detectorElement}
      className="banner_ad"
      style={{ height: '1px', position: 'fixed', bottom: 0 }}
    />
  );
};

export default BlockerDetector;
